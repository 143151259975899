<template lang="pug">
k-index-container.landing-v2-index-container-kol-show-container-event-campaign-index-container(
  style-type="landing-kol-box"
  :loading="campaignDataService.isLoading.value"
)
  template(#body)
    card-list(
      :promoter-share="promoterShare"
      :resources="campaignDataService.promoterCampaigns.value"
      :total="campaignDataService.meta.value.total_count"
      :current-page="campaignDataService.meta.value.current_page"
      :page-size="campaignDataService.queryOptions.pageSize"
      @page-change="campaignDataService.pageChangeHandler"
    )
  template(#footer)
    k-button(
      type="ci-blue"
      href="/kolcenter/event_campaigns"
    ) 更多活動
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'skid-composables'
import KIndexContainer from '@sharedComponents/common/k-index-container.vue'
import CardList from './event_campaign_index_container/card-list.vue'
import useCampaignDataService from '@kolcenter/js/composables/promoter_campaigns/use_official_campaign_data_service'
import KButton from '@sharedComponents/common/k-button.vue'
import axios from 'axios'

const props = defineProps({
  promoterShare: { type: Object }
})
const store = useStore()
const campaignDataService = useCampaignDataService({
  promoterShare: null,
  options: {
    search: {},
    pageNumber: 1,
    pageSize: 10
  }
})

const fetchPromoterCampaigns = async () => {
  if (props.promoterShare.isDataLoaded()) {
    campaignDataService.setScope('promoter_member')
  } else {
    campaignDataService.setScope('user')
  }

  campaignDataService.setCategories(['event'])

  await campaignDataService.fetchData()
}

onMounted(async () => {
  if (props.promoterShare.isDataLoaded())
    campaignDataService.setPromoterShare(props.promoterShare)

  await fetchPromoterCampaigns()
})
</script>
